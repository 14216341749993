import React from "react"
import NavCom from "./compnents/NavCom";
import Welcome from "./pages/Welcome";

function App() {
  return (
    <>
      <NavCom />
      <Welcome />
    </>
  );
}

export default App;
